import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Components/Redux/store';
import { setAuth } from '../Components/Redux/authSlice';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './Attendance.css'; // Ensure you have this file for custom styling

Modal.setAppElement('#root'); // Set the root element for accessibility

const Attendance: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth.value);

  const [formData, setFormData] = useState({
    date: '',
    status: '', // Attendance status (e.g., Present, Absent)
    class_id: '',
    student_id: '',
    remarks: '',
  });

  const [classes, setClasses] = useState<any[]>([]);
  const [students, setStudents] = useState<any[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get('https://api.samahait.com/Branches/classes/');
        setClasses(response.data);
        console.log('Classes fetched:', response.data); // Log fetched classes
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchClasses();
  }, []);

  const fetchStudentsByClass = async (classId: string) => {
    try {
      const response = await axios.get(`https://api.samahait.com/Branches/students/?class_id=${classId}`);
      setStudents(response.data.results || response.data); // Handle both cases
      console.log('Students fetched:', response.data); // Log fetched students
    } catch (error) {
      console.error('Error fetching students:', error);
      setStudents([]);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'class_id') {
      fetchStudentsByClass(value);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post('https://api.samahait.com/Branches/attendance/', {
        date: formData.date,
        status: formData.status,
        student: parseInt(formData.student_id), // Ensure ID is sent as integer
        class_associated: parseInt(formData.class_id), // Ensure ID is sent as integer
        remarks: formData.remarks, // Include remarks
      });
      alert('Attendance recorded successfully');
      setModalIsOpen(false); // Close the modal after successful submission
    } catch (error: any) {
      console.error('Error recording attendance:', error);
      alert('Error recording attendance. Please try again.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getCookie('token');
        console.log('Fetched Token:', token); // Print the token in console

        if (!token) {
          dispatch(setAuth(false));
          return;
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const { data } = await axios.get('https://api.samahait.com/users/user');
        console.log('User Data:', data); // Print user data to confirm successful authentication
        dispatch(setAuth(true));
      } catch (e) {
        console.error('Error fetching user data or token:', e); // Print any errors to the console
        dispatch(setAuth(false));
      }
    };

    fetchData();
  }, [dispatch]);

  if (!auth) {
    return <p>Loading...</p>; // Or redirect to login page if not authenticated
  }

  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="attendance-container">
      <button onClick={openModal} className="btn btn-primary">
        Add Attendance
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Record Attendance"
        className="attendance-modal"
        overlayClassName="attendance-modal-overlay"
      >
        <h2>Record Attendance</h2>
        <form onSubmit={handleSubmit} className="attendance-form">
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="class_id">Class</label>
            <select
              id="class_id"
              name="class_id"
              value={formData.class_id}
              onChange={handleInputChange}
              required
              className="form-control"
            >
              <option value="">Select a class</option>
              {classes.map((classItem) => (
                <option key={classItem.id} value={classItem.id}>
                  {classItem.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="student_id">Student</label>
            <select
              id="student_id"
              name="student_id"
              value={formData.student_id}
              onChange={handleInputChange}
              required
              className="form-control"
            >
              <option value="">Select a student</option>
              {students.map((student) => (
                <option key={student.id} value={student.id}>
                  {student.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleInputChange}
              required
              className="form-control"
            >
              <option value="">Select status</option>
              <option value="Present">Present</option>
              <option value="Absent">Absent</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="remarks">Remarks</label>
            <input
              type="text"
              id="remarks"
              name="remarks"
              value={formData.remarks}
              onChange={handleInputChange}
              className="form-control"
              placeholder="Optional"
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
          <button type="button" onClick={closeModal} className="btn btn-secondary">
            Close
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default Attendance;
