import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Authentications/Login';
import { Reset } from './Authentications/Reset';
import { Forgot } from './Authentications/Forgot';
import Register from './Authentications/Register';
import UserComponent from './Authentications/UserComponent';
import UserAccount from './Authentications/UserAccount';
import Dashboard from './Components/Dashboard';
import { Nav } from './Components/Nav';
import Sidebar from './Components/Sidebar';
import StudentCount from './Components/students/StudentCount';
import AddStudent from './Components/students/AddStudent';
import StudentDetails from './Components/students/StudentDetails';
import TeachersComponent from './Components/Teachers/TeachersComponent';
import AddTeacher from './Components/Teachers/AddTeachersComponent';
import AddClass from './Components/Classes/AddClass';
import Classes from './Components/Classes/ShowClass';
import ShowFees from './fees/ShowFees';
import AddFees from './fees/AddFees';
import DetailFees from './fees/DetailFees';
import FeesNotPaid from './fees/FeesNotPaid';
import ExpenseList from './Expenses/ExpenseList';
import ExpenseForm from './Expenses/ExpenseForm';
import IncomeExpenseSummary from './income/IncomeExpenseSummary';
import Salaries from './salaries/Salaries';
import AddSalaryForm from './salaries/AddSalaryForm';
import ShowExams from './Exam/ShowExams';
import Marks from './Exam/ShowMarks';
import AddMark from './Exam/AddMark';
import AddExam from './Exam/AddExam';
import BranchForm from './Branches/BranchForm';
import BranchList from './Branches/BranchList';
import AttendanceTable from './Attendance/AttendanceTable';
import ClassStudentTable from './Attendance/StudentItem ';
import StudentsClass from './Components/students/StudentsClass';
function App() {
    return (
      <BrowserRouter>
      <Nav/>
        <Sidebar/>
            <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/xarun" element={<BranchList />} />
                <Route path="/kudar-xarun" element={<BranchForm />} />
                {/* Students */}
                <Route path="/students" element={<StudentCount/>} />
                <Route path="/classOfStudent" element={<StudentsClass/>} />
                <Route path='/student-details/:id' element={<StudentDetails />} />
                <Route path="/add-students" element={<AddStudent />} />
                {/* Users */}
                <Route path="/" element={<Login />} />
                <Route path="/reset/:token" element={<Reset/>}/>
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/register" element={<Register/>} />
                <Route path="/users" element={<UserComponent />} />
                <Route path="/account" element={<UserAccount />} />
                {/* Teachers */}
                <Route path="/teachers" element={<TeachersComponent />} />
                <Route path="/add-teachers" element={<AddTeacher />} />
                {/* financials */}
                <Route path="/fees" element={<ShowFees/>} />
                <Route path="/add-fees" element={<AddFees/>} />
                <Route path='/:id/' element={<DetailFees/>} />
                <Route path="/income" element={<IncomeExpenseSummary/>} />
                <Route path="/ExpenseList" element={<ExpenseList/>} />
                <Route path="/expenses" element={<ExpenseForm/>} />
                <Route path="/salaries" element={<Salaries/>} />
                <Route path="/fees_not_paid" element={<FeesNotPaid/>} />
                <Route path="/add-salary" element={<AddSalaryForm/>} />
                {/* Exams  */}
                <Route path="/exams" element={<ShowExams/>} />
                <Route path="/marks" element={<Marks/>} />
                <Route path="/add-marks" element={<AddMark/>} />
                <Route path="/add-exams" element={<AddExam onSuccess={() => {}} />}/>
                    {/* Branches */}
                {/* <Route path="/mustaqbal" element={<Mustaqbal/>} />
                <Route path="/bayan" element={<Bayan/>} /> */}
                {/* Classes */}
                <Route path="/classes" element={<Classes/>} />
                <Route path="/add-classes" element={<AddClass/>} />
                {/* Attendances*/}
                StudentItem
                <Route path="/attendance" element={<AttendanceTable/>} />
                <Route path="/attendance-table" element={<ClassStudentTable/>}/>

                
            </Routes>
        </BrowserRouter>
    );
}

export default App;
