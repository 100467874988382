import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { setAuth } from '../Components/Redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Components/Redux/store';

interface Salary {
  id: number;
  teacher: number;
  teacher_name: string;
  month: string;
  amount: string;
  date_paid: string;
  branch: number;
}

// Function to get cookie value by name
const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
};

const Salaries: React.FC = () => {
  const [salaries, setSalaries] = useState<Salary[]>([]);
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [editableSalary, setEditableSalary] = useState<Salary | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth.value);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const { data } = await axios.get('https://api.samahait.com/users/user');
        dispatch(setAuth(true));
      } catch (e) {
        dispatch(setAuth(false)); // Corrected dispatch usage
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchSalaries = async () => {
      try {
        const response = await axios.get('https://api.samahait.com/fees/salaries/');
        setSalaries(response.data);
      } catch (err) {
        setError('Error fetching salary data');
      } finally {
        setLoading(false);
      }
    };

    fetchSalaries();
  }, []);

  const handleEditClick = async (salary: Salary) => {
    if (isEditing !== null && editableSalary) {
      try {
        const response = await axios.patch(`https://api.samahait.com/fees/salaries/${editableSalary.id}/`, editableSalary, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Server response:', response.data);
        setSalaries(salaries.map(s => (s.id === editableSalary.id ? { ...editableSalary, ...response.data } : s)));
      } catch (error: any) {
        console.error('Error updating salary:', error.response ? error.response.data : error.message);
      }
      setIsEditing(null);
      setEditableSalary(null);
    } else {
      setIsEditing(salary.id);
      setEditableSalary(salary);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (editableSalary) {
      setEditableSalary({ ...editableSalary, [name]: value });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mt-2">
      <h2 style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Salary Details</h2>
      {auth ? (
      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered table-sm">
          <thead className='thead-dark'>
            <tr>
              <th>Teacher Name</th>
              <th>Month</th>
              <th>Amount</th>
              <th>Date Paid</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {salaries.map(salary => (
              <tr key={salary.id}>
                <td>
                  {isEditing === salary.id ? (
                    <input
                      type="text"
                      name="teacher_name"
                      value={editableSalary?.teacher_name || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    salary.teacher_name
                  )}
                </td>
                <td>
                  {isEditing === salary.id ? (
                    <input
                      type="text"
                      name="month"
                      value={editableSalary?.month || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    salary.month
                  )}
                </td>
                <td>
                  {isEditing === salary.id ? (
                    <input
                      type="text"
                      name="amount"
                      value={editableSalary?.amount || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    `${salary.amount} ksh`
                  )}
                </td>
                <td>
                  {isEditing === salary.id ? (
                    <input
                      type="text"
                      name="date_paid"
                      value={editableSalary?.date_paid || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    salary.date_paid
                  )}
                </td>
                <td>
                  <button className="btn btn-primary" onClick={() => handleEditClick(salary)}>
                    {isEditing === salary.id ? 'Save' : 'Edit'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      ) : (
        <div>
          <h4 style={{textAlign:"center", marginTop:"20px"}}>You are not authenticated</h4>
        </div>
      )}
    </div>
  );
};

export default Salaries;
