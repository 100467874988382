import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { setAuth } from '../Redux/authSlice';
import { useDispatch } from 'react-redux';

interface Class {
  id: number;
  name: string;
}

const Classes: React.FC = () => {
  const [classes, setClasses] = useState<Class[]>([]);
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [editableClass, setEditableClass] = useState<Class | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  // Function to get a cookie by name
  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  // Fetch user data and check authentication
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const { data } = await axios.get('https://api.samahait.com/users/user');
        dispatch(setAuth(true));
      } catch (e) {
        dispatch(setAuth(false));
      }
    };

    fetchUserData();
  }, [dispatch]);

  // Fetch classes data
  useEffect(() => {
    const fetchClassesData = async () => {
      try {
        const response = await axios.get('https://api.samahait.com/Branches/classes/');
        setClasses(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching class data');
        setLoading(false);
      }
    };

    fetchClassesData();
  }, []);

  // Handle editing a class
  const handleEditClick = async (classItem: Class) => {
    if (isEditing !== null && editableClass) {
      try {
        const response = await axios.patch(
          `https://api.samahait.com/Branches/classes/${editableClass.id}/`,
          editableClass,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('Server response:', response.data);
        setClasses(classes.map(c => (c.id === editableClass.id ? { ...editableClass, ...response.data } : c)));
      } catch (error: any) {
        console.error('Error updating class:', error.response ? error.response.data : error.message);
      }
      setIsEditing(null);
      setEditableClass(null);
    } else {
      setIsEditing(classItem.id);
      setEditableClass(classItem);
    }
  };

  // Handle input change for editable class
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (editableClass) {
      setEditableClass({ ...editableClass, [name]: value });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mt-1">
      <h2 style={{ textAlign: "center" }}>Class Details</h2>
      <div className="table-responsive table-striped">
        <table className="table table-striped table-hover table-bordered table-sm">
          <thead>
            <tr>
              <th>Class Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {classes.map(classItem => (
              <tr key={classItem.id}>
                <td>
                  {isEditing === classItem.id ? (
                    <input
                      type="text"
                      name="name"
                      value={editableClass?.name || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    classItem.name
                  )}
                </td>
                <td>
                  <button className="btn btn-primary" onClick={() => handleEditClick(classItem)}>
                    {isEditing === classItem.id ? 'Save' : 'Edit'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Classes;
