import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAuth } from '../Redux/authSlice';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './StudentCount.css'; // Custom CSS for additional styling

const StudentCount: React.FC = () => {
    const [studentCount, setStudentCount] = useState<number | null>(null);
    const [students, setStudents] = useState<any[]>([]);
    const [classes, setClasses] = useState<any[]>([]); // State to hold classes
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [userRole, setUserRole] = useState<string | null>(null);
    const [authenticated, setAuthenticated] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searching, setSearching] = useState<boolean>(false);
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth); // Initialize with current window width
    const dispatch = useDispatch();

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async (page: number, query?: string) => {
            try {
                const token = getCookie('token');
                if (!token) {
                    console.error('No token found in cookies');
                    dispatch(setAuth(false));
                    setAuthenticated(false);
                    setError('No token found. Please log in.');
                    return;
                }
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const userResponse = await axios.get('https://api.samahait.com/users/user');
                setUserRole(userResponse.data.user.role);
                setAuthenticated(true);
                dispatch(setAuth(true));

                // Fetch classes
                const classesResponse = await axios.get('https://api.samahait.com/Branches/classes/');
                setClasses(classesResponse.data);

                let apiUrl = `https://api.samahait.com/Branches/students/?page=${page}`;
                if (query) {
                    apiUrl += `&name=${encodeURIComponent(query)}`;
                }

                const studentsResponse = await axios.get(apiUrl);
                const studentsData = studentsResponse.data.results.map((student: any) => ({
                    ...student,
                    class_name: getClassById(student.class_associated, classesResponse.data), // Get class name using class ID
                }));
                setStudents(studentsData);

                const totalItems = studentsResponse.data.count;
                const pageSize = studentsResponse.data.page_size;
                setTotalPages(Math.ceil(totalItems / pageSize));
            } catch (err) {
                console.error('Error fetching data:', err);
                dispatch(setAuth(false));
                setAuthenticated(false);
                if (axios.isAxiosError(err)) {
                    if (err.response) {
                        setError(`Error: ${err.response.status} ${err.response.statusText}`);
                    } else if (err.request) {
                        setError('Error: No response received from the server');
                    } else {
                        setError(`Error: ${err.message}`);
                    }
                } else {
                    setError('An unexpected error occurred');
                }
            } finally {
                setLoading(false);
                setSearching(false);
            }
        };

        fetchData(currentPage, searchQuery);
    }, [currentPage, searchQuery, dispatch]);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    const getClassById = (classId: number, classes: any[]) => {
        const foundClass = classes.find(cls => cls.id === classId);
        return foundClass ? foundClass.name : 'Unknown'; // Return class name or 'Unknown' if not found
    };

    const handlePageChange = async (newPage: number) => {
        console.log(`Changing to page: ${newPage}`);
        try {
            setLoading(true);

            const token = getCookie('token');
            if (!token) {
                throw new Error('No token found in cookies');
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const apiUrl = `https://api.samahait.com/Branches/students/?page=${newPage}`;
            const studentsResponse = await axios.get(apiUrl);
            const studentsData = studentsResponse.data.results.map((student: any) => ({
                ...student,
                class_name: getClassById(student.class_associated, classes), // Get class name using class ID
            }));
            setStudents(studentsData);

            const totalItems = studentsResponse.data.count;
            const pageSize = studentsResponse.data.page_size;
            setTotalPages(Math.ceil(totalItems / pageSize));
            setLoading(false);
            setCurrentPage(newPage); // Update currentPage after successful fetch
        } catch (err) {
            console.error('Error fetching data:', err);
            dispatch(setAuth(false));
            setAuthenticated(false);
            if (axios.isAxiosError(err)) {
                if (err.response) {
                    setError(`Error: ${err.response.status} ${err.response.statusText}`);
                } else if (err.request) {
                    setError('Error: No response received from the server');
                } else {
                    setError(`Error: ${err.message}`);
                }
            } else {
                setError('An unexpected error occurred');
            }
            setLoading(false);
        }
    };

    const handleSearch = () => {
        setCurrentPage(1); // Reset to first page when searching
        setSearching(true);
    };

    return (
        <div className="main-content">
            <div className="search-container" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-control"
                style={{width:"700px"}}/>
                      <Link to="/add-students" className="btn btn-primary btn-sm" style={{ color: 'white' }}>
                        Add Student
                        </Link>
            </div>
            {loading ? (
                <div className="text-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : error ? (
                <div className="alert alert-danger mt-3">{error}</div>
            ) : (
            <div className="table-responsive mt-1">
                <table className="table table-striped table-hover table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th>Name</th>
                            <th>Class</th>
                            <th>Date Registered</th>
                            <th>Guardian Number</th>
                            <th>Health Status</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {students.map((student) => (
                            <tr key={student.id}>
                                <td>{student.name}</td>
                                <td>{student.class_name}</td> {/* Display class_name here */}
                                <td>{student.date_reg}</td>
                                <td>{student.guardian_number}</td>
                                <td>{student.health_status}</td>
                                <td>
                                    <Link to={`/student-details/${student.id}`} className="btn btn-primary btn-sm">
                                        Details
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            )}
            <nav>
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};
export default StudentCount;
