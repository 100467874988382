import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../Components/Redux/store';
import { setAuth } from '../Components/Redux/authSlice';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

interface Fee {
  id: number;
  student_name: string;
  month: string;
  amount_paid: string;
  date_paid: string;
  class_name: string;
  balance: string;
  receipt_number: number;
}

export default function DetailFees() {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth.value);
  const [userInfo, setUserInfo] = useState<{
    firstName: string;
    lastName: string;
    branchLogo: string;
    branchName: string;
    Address: string;
    contactNumber: string;
  }>({ firstName: '', lastName: '', branchLogo: '', branchName: '', Address: '', contactNumber: '' });
  const [student, setStudent] = useState<Fee>({} as Fee);
  const [error, setError] = useState<string | null>(null);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const { data } = await axios.get('https://api.samahait.com/users/user');

        const { first_name, last_name, branch_logo, branch_name, address, contact_number } = data.user;
        setUserInfo({ firstName: first_name, lastName: last_name, branchLogo: branch_logo, branchName: branch_name, Address: address, contactNumber: contact_number });

        dispatch(setAuth(true));
      } catch (e) {
        dispatch(setAuth(false));
        setError('Failed to fetch user data');
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const getSingleStudent = async () => {
      try {
        const { data } = await axios.get<Fee>(`https://api.samahait.com/fees/fees/${id}/`);
        setStudent(data);
      } catch (error) {
        console.error('Error fetching student:', error);
      }
    };

    getSingleStudent();
  }, [id]);

  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  const downloadInvoice = async () => {
    const doc = new jsPDF();

    // Header with logo
    if (userInfo.branchLogo) {
      const logoUrl = `http://127.0.0.1:8000${userInfo.branchLogo}`;
      const imgData = await getImageFromUrl(logoUrl);
      doc.addImage(imgData, 'JPEG', 15, 10, 30, 30);
    }

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text(userInfo.branchName, 105, 20, { align: 'center' });
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(userInfo.Address, 105, 36, { align: 'center' });
    doc.text("Call: " + userInfo.contactNumber, 105, 42, { align: 'center' });

    // Add horizontal line below the header
    doc.line(15, 52, 195, 52);

    // Receipt Information
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(`Receipt No: ${student.receipt_number}`, 14, 60);
    doc.text(`Date: ${student.date_paid}`, 150, 60);

    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Received from:", 14, 70);
    doc.setFont("helvetica", "bold");
    doc.text(student.student_name, 50, 70);

    doc.setFont("helvetica", "normal");
    doc.text("The sum in Shillings:", 14, 80);
    doc.setFont("helvetica", "bold");
    doc.text(`${student.amount_paid}`, 50, 80);

    doc.setFont("helvetica", "normal");
    doc.text("Class:", 14, 90);
    doc.setFont("helvetica", "bold");
    doc.text(student.class_name, 50, 90);

    doc.setFont("helvetica", "normal");
    doc.text("Mode of Payment:", 14, 100);
    doc.setFont("helvetica", "normal");
    doc.text("[ ] Cash  [ ] Mpesa  [ ] Cheque", 50, 100);

    doc.setFont("helvetica", "normal");
    doc.text("Balance:", 14, 110);
    doc.setFont("helvetica", "bold");
    doc.text(`${student.balance}`, 50, 110);

    // Add a footer
    doc.setFontSize(10);
    doc.setFont("helvetica", "italic");
    doc.text("Thank you for your payment!", 105, 130, { align: 'center' });

    // Save the PDF with a meaningful file name
    doc.save(`Receipt_${student.receipt_number}.pdf`);
  };

  const getImageFromUrl = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL('image/jpeg'));
        } else {
          reject('Failed to get canvas context');
        }
      };
      img.onerror = () => {
        reject('Failed to load image');
      };
      img.src = url;
    });
  };

  return (
    <>
      <div className="container mt-5 receipt">
        {auth ? (
          <div className="row justify-content-center">
            <div className="col-md-7">
              <div className="card" style={{ backgroundColor: "white" }}>
                <div className="card-header text-center">
                  <div className="d-flex justify-content-between align-items-center">
                    {userInfo ? (
                      <img
                        src={userInfo.branchLogo}
                        alt="Branch Logo"
                        className="logoIcon"
                        style={{ width: '50px', marginRight: '10px' }}
                      />
                    ) : (
                      <p>Loading logo...</p>
                    )}
                    <div>
                      <h5>{userInfo.branchName}</h5>
                    </div>
                    <div className="cards" style={{ background: "none" }}>
                      <small>
                        {userInfo.Address}
                        <br />
                        {userInfo.contactNumber}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-6">
                      <strong>No.</strong>
                      <p className="border-bottom" style={{ marginBottom: '0', color: "red" }}>{student.receipt_number}</p>
                    </div>
                    <div className="col-6 text-end">
                      <strong>Date</strong>
                      <p className="border-bottom" style={{ marginBottom: '0' }}>{student.date_paid}</p>
                    </div>
                  </div>
                  <div className="mb-3">
                    <strong>Student Name</strong>
                    <p className="border-bottom" style={{ marginBottom: '0' }}>{student.student_name}</p>
                  </div>
                  <div className="mb-3">
                    <strong>Amount Paid</strong>
                    <p className="border-bottom" style={{ marginBottom: '0' }}> {student.amount_paid}</p>
                  </div>
                  <div className="mb-3">
                    <strong>Class</strong>
                    <p className="border-bottom" style={{ marginBottom: '0' }}>{student.class_name}</p>
                  </div>
                  <div className="mb-3">
                    <strong>Balance</strong>
                    <p className="border-bottom" style={{ marginBottom: '0' }}>{student.balance}</p>
                  </div>
                  <div className="mb-3">
                    <strong>Mode of Payment</strong>
                    <p className="border-bottom" style={{ marginBottom: '0' }}>
                      <input type="checkbox" /> Cash <input type="checkbox" /> Mpesa <input type="checkbox" /> Cheque
                    </p>
                  </div>
                  <div className="text-center">
                    <button className="btn btn-primary" onClick={downloadInvoice}>
                      Download PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            {error ? <p>{error}</p> : <p>Loading...</p>}
          </div>
        )}
      </div>
    </>
  );
}
