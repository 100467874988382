import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { RootState } from '../Components/Redux/store';
import { useSelector } from 'react-redux';
import { setAuth } from '../Components/Redux/authSlice';

const ExpenseForm: React.FC = () => {
    const [expenseType, setExpenseType] = useState<string>('');
    const [cost, setCost] = useState<number | ''>('');
    const [datePaid, setDatePaid] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', role: '' });
    const auth = useSelector((state: RootState) => state.auth.value);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!expenseType || !cost || !datePaid) {
            setErrorMessage('All fields are required');
            return;
        }

        try {
            const response = await axios.post<{ message: string }>('https://api.samahait.com/fees/expenses/', {
                Expenses_type: expenseType,
                Cost: parseInt(cost as unknown as string),
                date_paid_expenses: datePaid
            });

            console.log('Expense created:', response.data);
            setExpenseType('');
            setCost('');
            setDatePaid('');
            setErrorMessage('');
            window.location.href = "/ExpenseList"
        } catch (error) {
            console.error('Error creating expense:', error);
            setErrorMessage('Error creating expense');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const { data } = await axios.get('https://api.samahait.com/users/user');
                setUserInfo({ firstName: data.first_name, lastName: data.last_name, role: data.role });
                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };
        fetchData();
    }, [dispatch]);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    return (
        <div className="container">
            {auth ? (
                <div className="row justify-content-center mt-5">
                    <div className="col-md-6">
                        <div className="card shadow-lg border-0 rounded-lg">
                            <div className="card-body">
                                <h2 className="card-title text-center mb-4">Create Expense</h2>
                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="expenseType" className="form-label">Expense Type:</label>
                                        <input
                                            type="text"
                                            id="expenseType"
                                            className="form-control form-control-lg"
                                            value={expenseType}
                                            onChange={(e) => setExpenseType(e.target.value)}
                                            placeholder="Enter the type of expense"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="cost" className="form-label">Cost:</label>
                                        <input
                                            type="number"
                                            id="cost"
                                            className="form-control form-control-lg"
                                            value={cost}
                                            onChange={(e) => setCost(parseFloat(e.target.value))}
                                            placeholder="Enter the cost"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="datePaid" className="form-label">Date Paid:</label>
                                        <input
                                            type="date"
                                            id="datePaid"
                                            className="form-control form-control-lg"
                                            value={datePaid}
                                            onChange={(e) => setDatePaid(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="d-grid">
                                        <button type="submit" className="btn btn-primary btn-lg">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="text-center mt-5">
                    <h3>You are not authenticated or authorized to view this page</h3>
                </div>
            )}
        </div>
    );
};

export default ExpenseForm;
