import React, { SyntheticEvent, useState } from "react";
import axios from "axios";

export const Forgot = () => {
    const [email, setEmail] = useState('');
    const [notify, setNotify] = useState({
        show: false,
        error: false,
        message: ''
    });

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        try {
            await axios.post('https://api.samahait.com/users/forgot', { email });
            setNotify({
                show: true,
                error: false,
                message: 'Please check your email!'
            });
        } catch (e) {
            setNotify({
                show: true,
                error: true,
                message: 'Error occurred!'
            });
        }
    }

    let info;

    if (notify.show) {
        info = <div className={notify.error ? 'alert alert-danger' : 'alert alert-success'} role="alert">
            {notify.message}
        </div>
    }

    return (
        <div className="container">
            <div className="row g-0 rounded-lg border bg-white overflow-hidden shadow">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img
                        src="/images/dugsi.jpg"
                        alt="Dugsi Manager Logo"
                        className="img-fluid rounded"
                        style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                    />
                </div>
                <div className="col-md-6 p-4 d-flex justify-content-center align-items-center">
                    <div>
                        {info}
                        <form onSubmit={submit}>
                            <h1 className="h3 mb-3 fw-normal">Forgot your password?</h1>
                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <label htmlFor="floatingInput">Email address</label>
                            </div>
                            <button className="w-100 btn btn-lg btn-primary mt-3" type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
