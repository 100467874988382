import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Components/Redux/store';
import { setAuth } from '../Components/Redux/authSlice';
import { Navigate, useNavigate } from 'react-router-dom';

const AddFees: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth.value);

  const [formData, setFormData] = useState({
    month: '',
    amount_paid: '', // Initialize with ''
    total_amount: '', // Initialize with ''
    date_paid: '',
    enrolled_class: '', // Ensure this matches backend field name if needed
    collected_by: '',
    student_id: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [classes, setClasses] = useState<any[]>([]);
  const [students, setStudents] = useState<any[]>([]);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get('https://api.samahait.com/Branches/classes/');
        setClasses(response.data);
      } catch (error) {
        console.error('Error fetching classes:', error);
        // Handle error fetching classes
      }
    };

    fetchClasses();
  }, []);

  const fetchStudentsByClass = async (classId: string) => {
    try {
      const response = await axios.get(`https://api.samahait.com/Branches/students/?class_id=${classId}`);
      setStudents(response.data.results);
    } catch (error) {
      console.error('Error fetching students:', error);
      setStudents([]);
      // Handle error fetching students
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'enrolled_class') {
      fetchStudentsByClass(value);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post('https://api.samahait.com/fees/fees/', {
        student: formData.student_id,
        class_associated: formData.enrolled_class, // Ensure this matches backend field name
        amount_paid: parseFloat(formData.amount_paid), // Convert to float if necessary
        total_amount: parseFloat(formData.total_amount), // Convert to float if necessary
        date_paid: formData.date_paid,
        month: formData.month,
        collected_by: formData.collected_by,
      });
      setIsSubmitted(true);
    } catch (error: any) {
      console.error('Error adding fee:', error);
      if (error.response && error.response.status === 404) {
        alert('Student not found. Please enter a valid student.');
      } else {
        alert('Error adding fee. Please try again.');
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const { data } = await axios.get('https://api.samahait.com/users/user');
        dispatch(setAuth(true));
      } catch (e) {
        dispatch(setAuth(false));
      }
    };

    fetchData();
  }, [dispatch]);

  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  if (isSubmitted) {
    return <Navigate to="/fees" />;
  }

  return (
    <div className="container " style={{marginTop:"80px"}}>
      {auth ? (
      <div className="row">
        <div className="col-md-9">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4" style={{ width: '70%', borderRadius: '10px', backgroundColor: "white" }}>
              <h2 className="text-center mb-2">Add Fee</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Select Class:</label>
                  <select className="form-control" style={{ width: '100%', height: '40px' }} name="enrolled_class" value={formData.enrolled_class} onChange={handleInputChange}>
                    <option value="">Select Class</option>
                    {classes.map((cls) => (
                      <option key={cls.id} value={cls.id}>{cls.name}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Select Student:</label>
                  <select className="form-control" style={{ width: '100%', height: '40px' }} name="student_id" value={formData.student_id} onChange={handleInputChange}>
                    <option value="">Select Student</option>
                    {students.map((student) => (
                      <option key={student.id} value={student.id}>{student.name}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Month:</label>
                  <select className="form-control" style={{ width: '100%', height: '35px' }} name="month" value={formData.month} onChange={handleInputChange}>
                    <option value="">Select Month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>
                <div className="mb-2">
                  <label className="form-label">Amount Paid:</label>
                  <input type="text" className="form-control" style={{ width: '100%', height: '35px' }} name="amount_paid" value={formData.amount_paid} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Total Amount:</label>
                  <input type="text" className="form-control" style={{ width: '100%', height: '35px' }} name="total_amount" value={formData.total_amount} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Date Paid:</label>
                  <input type="date" className="form-control" style={{ width: '100%', height: '35px' }} name="date_paid" value={formData.date_paid} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Collected By:</label>
                  <input type="text" className="form-control" style={{ width: '100%', height: '35px' }} name="collected_by" value={formData.collected_by} onChange={handleInputChange} />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
       ) : (
        <div>
          <h4 style={{textAlign:"center", marginTop:"20px"}}>You are not authenticated</h4>
        </div>
      )}
    </div>
  );
};

export default AddFees;
