// AddExam.tsx
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { RootState } from '../Components/Redux/store';

interface AddExamProps {
  onSuccess: () => void; // Define onSuccess prop
}

const AddExam: React.FC<AddExamProps> = ({ onSuccess }) => {
  const [examName, setExamName] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const auth = useSelector((state: RootState) => state.auth.value);

  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const token = getCookie('token');
      if (!token) {
        setError('No authentication token found. Please log in.');
        return;
      }

      const newExam = { exam_name: examName, date };
      const response = await axios.post('https://api.samahait.com/exams/exams/', newExam, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      onSuccess(); // Call onSuccess callback after successful exam creation
      setExamName('');
      setDate('');
      navigate('/exams'); // Navigate to /exams after successful exam creation
    } catch (error: any) {
      console.error('Error creating exam:', error.response ? error.response.data : error.message);
      setError(error.response?.data?.detail || 'Error creating exam');
    }
  };

  return (
    <div className="card mt-3" style={{ maxWidth: '600px', margin: '30px auto' }}> {/* Adjusted width and margin */}
      {auth ? (
      <div className="card-body">
        <h3 className="card-title">Add New Exam</h3>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleFormSubmit}>
          <div className="mb-3">
            <label htmlFor="examName" className="form-label">Exam Name</label>
            <input
              type="text"
              className="form-control"
              id="examName"
              value={examName}
              onChange={(e) => setExamName(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="date" className="form-label">Date</label>
            <input
              type="date"
              className="form-control"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Add Exam</button>
        </form>
      </div>
      ) : (
        <div>
          <h4 style={{textAlign:"center", marginTop:"20px"}}>You are not authenticated</h4>
        </div>
      )}
    </div>
  );
};

export default AddExam;
