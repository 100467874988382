import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../Components/Redux/store';

interface Student {
  id: number;
  name: string;
}

interface Examination {
  id: number;
  exam_name: string;
}

interface Class {
  id: number;
  name: string;
}

interface Mark {
  student: number;
  examination: number;
  file: File | null;
}

const AddMark: React.FC = () => {
  const [classes, setClasses] = useState<Class[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [examinations, setExaminations] = useState<Examination[]>([]);
  const [newMark, setNewMark] = useState<Mark>({ student: 0, examination: 0, file: null });
  const [selectedClass, setSelectedClass] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const auth = useSelector((state: RootState) => state.auth.value);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get('https://api.samahait.com/Branches/classes/');
        setClasses(response.data);
      } catch (error) {
        console.error('Error fetching classes:', error);
        setError('Failed to fetch classes');
      }
    };

    const fetchExaminations = async () => {
      try {
        const response = await axios.get('https://api.samahait.com/exams/exams/');
        setExaminations(response.data);
      } catch (error) {
        console.error('Error fetching examinations:', error);
        setError('Failed to fetch examinations');
      }
    };

    fetchClasses();
    fetchExaminations();
  }, []);

  const fetchStudentsByClass = async (classId: number) => {
    try {
      const response = await axios.get(`https://api.samahait.com/Branches/students/?class_id=${classId}`);
      setStudents(response.data.results);
    } catch (error) {
      console.error('Error fetching students:', error);
      setStudents([]);
      setError('Failed to fetch students');
    }
  };

  const handleClassChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const classId = parseInt(e.target.value);
    setSelectedClass(classId);
    setNewMark({ ...newMark, student: 0 }); // Reset student selection
    fetchStudentsByClass(classId);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setNewMark({ ...newMark, file: event.target.files[0] });
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('student', newMark.student.toString());
    formData.append('examination', newMark.examination.toString());
    if (newMark.file) {
      formData.append('file', newMark.file);
    }

    try {
      await axios.post('https://api.samahait.com/exams/marks/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccessMessage('Mark added successfully');
      setNewMark({ student: 0, examination: 0, file: null });
      setTimeout(() => setSuccessMessage(null), 3000);  // Remove success message after 3 seconds
    } catch (error) {
      setError('Failed to add mark');
    }
  };

  return (
    <div className="container mt-5" style={{ marginLeft: "200px", width: "800px", boxShadow: "1px 2px 4px grey", padding: "20px" }}>
      <h1 className="mb-4">Add Result</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      {auth ? (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Class</label>
          <select
            className="form-control"
            value={selectedClass || ''}
            onChange={handleClassChange}
          >
            <option value="">Select Class</option>
            {classes.map((classItem) => (
              <option key={classItem.id} value={classItem.id}>
                {classItem.name}
              </option>
            ))}
          </select>
        </div>
        {selectedClass && (
          <div className="form-group">
            <label>Student</label>
            <select
              className="form-control"
              value={newMark.student}
              onChange={e => setNewMark({ ...newMark, student: parseInt(e.target.value) })}
            >
              <option value="">Select Student</option>
              {students.map(student => (
                <option key={student.id} value={student.id}>
                  {student.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="form-group">
          <label>Examination</label>
          <select
            className="form-control"
            value={newMark.examination}
            onChange={e => setNewMark({ ...newMark, examination: parseInt(e.target.value) })}
          >
            <option value="">Select Examination</option>
            {examinations.map(exam => (
              <option key={exam.id} value={exam.id}>
                {exam.exam_name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>File</label>
          <input
            type="file"
            className="form-control"
            onChange={handleFileChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Add Mark</button>
      </form>
    ) : (
          <div>
            <h4 style={{textAlign:"center", marginTop:"20px"}}>You are not authenticated</h4>
          </div>
        )}
    </div>
  );
};

export default AddMark;
