import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { setAuth } from '../Redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import './StudentCount.css'; // Import your custom CSS file
import { Link } from 'react-router-dom';
import { RootState } from '../Redux/store';

export default function StudentsClass() {
  const [classes, setClasses] = useState<any[]>([]);
  const [selectedClass, setSelectedClass] = useState<number | null>(null);
  const [students, setStudents] = useState<any[]>([]);
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth.value);


  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const { data } = await axios.get('https://api.samahait.com/users/user');
        dispatch(setAuth(true));
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    fetchUserData();
  }, [dispatch]);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get('https://api.samahait.com/Branches/classes/');
        setClasses(response.data);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchClasses();
  }, []);

  useEffect(() => {
    if (selectedClass) {
      const fetchStudents = async () => {
        try {
          const response = await axios.get(`https://api.samahait.com/Branches/students/?class_id=${selectedClass}`);
          setStudents(response.data.results || response.data);
        } catch (error) {
          console.error('Error fetching students:', error);
          setStudents([]);
        }
      };

      fetchStudents();
    }
  }, [selectedClass]);

  const handleClassChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const classId = Number(e.target.value);
    setSelectedClass(classId);
  };

  return (
    <div className="students-class-container">
       {auth ? (
      <>
      <h2 className="students-class-title">Select Class to View Students</h2>
      <div className="class-select-container">
        <label htmlFor="classSelect" className="class-select-label">Class:</label>
        <select id="classSelect" onChange={handleClassChange} value={selectedClass || ''} className="class-select">
          <option value="" disabled>Select a class</option>
          {classes.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.name}
            </option>
          ))}
        </select>
      </div>
      {selectedClass && (
        <div className="students-list-container">
          <h3 className="students-list-title">Students in Selected Class</h3>
          {students.length > 0 ? (
            <table className="students-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Guardian Number</th>
                  <th>Address</th>
                  <th>Health Status</th>
                  <th>Date reg</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={student.id}>
                    <td>{student.name}</td>
                    <td>{student.guardian_number}</td>
                    <td>{student.address}</td>
                    <td>{student.health_status}</td>
                    <td>{student.date_reg}</td>
                    <Link to={`/student-details/${student.id}`} className="btn btn-primary btn-sm">
                    Details
                    </Link>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="no-students">No students found for this class.</p>
          )}
          
        </div>
      )}
      </>
    ) : (
      <div>
        <h4 style={{textAlign:"center", marginTop:"20px"}}>You are not authenticated</h4>
      </div>
    )}
    </div>
  );
}

