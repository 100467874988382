import React, { useEffect, useState, SyntheticEvent } from "react";
import axios from "axios";
import { Navigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../Components/Redux/store";

const UserAccount: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [notify, setNotify] = useState({
        show: false,
        error: false,
        message: ''
    });
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState<any>(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const { token } = useParams();
    const auth = useSelector((state: RootState) => state.auth.value);


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = getCookie('token');
                console.log('Token fetched from cookies:', token); // Log the token

                if (!token) {
                    setNotify({
                        show: true,
                        error: true,
                        message: 'No token found. Please log in.'
                    });
                    return;
                }
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const response = await axios.get('https://api.samahait.com/users/user');
                console.log('Fetch user data response:', response.data); // Log the response
                setUserData(response.data.user); // Update to set only the user object from response
                setEmail(response.data.user.email); // Update email state separately if needed
            } catch (error) {
                console.error('Error fetching user data:', error); // Log the error
                setNotify({
                    show: true,
                    error: true,
                    message: 'Failed to fetch user data'
                });
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const token = getCookie('token');
        console.log('Token fetched from cookies on submit:', token); // Log the token

        if (!token) {
            setNotify({
                show: true,
                error: true,
                message: 'No token found. Please log in.'
            });
            return;
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        if (token) {
            // Reset password
            try {
                const response = await axios.post('https://api.samahait.com/users/reset/', {
                    token,
                    password,
                    password_confirm: passwordConfirm
                });
                console.log('Reset password response:', response.data); // Log the response
                setRedirect(true);
            } catch (error) {
                console.error('Error resetting password:', error); // Log the error
                setNotify({
                    show: true,
                    error: true,
                    message: 'Error resetting password'
                });
            }
        } else {
            // Update user data
            try {
                const response = await axios.put('https://api.samahait.com/users/update/', {
                    email,
                    password,
                    password_confirm: passwordConfirm
                });
                console.log('Update user data response:', response.data); // Log the response
                setNotify({
                    show: true,
                    error: false,
                    message: 'User data updated successfully!'
                });
                setIsUpdating(false); // Exit update mode after successful update
            } catch (error) {
                console.error('Error updating user data:', error); // Log the error
                setNotify({
                    show: true,
                    error: true,
                    message: 'Error occurred while updating user data!'
                });
            }
        }
    };

    if (redirect) {
        return <Navigate to="/" />;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container" style={{marginLeft:"200px"}}>
            {auth ? (
            <div className="row">
                <div className="col-md-4">
                    <h4>User Information</h4>
                    {userData && (
                        <div className="card">
                            <div className="card-body">
                                <p><strong>Email:</strong> {userData.email}</p>
                                <p><strong>First Name:</strong> {userData.first_name}</p>
                                <p><strong>Last Name:</strong> {userData.last_name}</p>
                                <p><strong>Role:</strong> {userData.role}</p>
                                <p><strong>Branch Name:</strong> {userData.branch_name}</p>
                            </div>
                        </div>
                    )}
                    {!isUpdating && (
                        <button className="btn btn-primary mt-3" onClick={() => setIsUpdating(true)}>
                            Update Info
                        </button>
                    )}
                </div>
                <div className="col-md-5">
                    {isUpdating && (
                        <main className="form-signin">
                            {notify.show && (
                                <div className={notify.error ? 'alert alert-danger' : 'alert alert-success'} role="alert">
                                    {notify.message}
                                </div>
                            )}

                            <form onSubmit={handleSubmit}>
                                <h1 className="h3 mb-3 fw-normal">{token ? 'Reset your password' : 'Update your account'}</h1>

                                <div className="form-floating">
                                    <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        readOnly={!!token}
                                    />
                                    <label htmlFor="floatingInput">Email address</label>
                                </div>

                                <div className="form-floating">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                    <label htmlFor="floatingPassword">Password</label>
                                </div>

                                <div className="form-floating">
                                    <input type="password" className="form-control" id="floatingPasswordConfirm" placeholder="Password Confirm"
                                        onChange={e => setPasswordConfirm(e.target.value)}
                                    />
                                    <label htmlFor="floatingPasswordConfirm">Password Confirm</label>
                                </div>

                                <button className="w-100 btn btn-lg btn-primary mt-3" type="submit">Submit</button>
                                <button className="w-100 btn btn-lg btn-secondary mt-3" type="button" onClick={() => setIsUpdating(false)}>
                                    Cancel
                                </button>
                            </form>
                        </main>
                    )}
                </div>
            </div>
             ) : (
                <div>
                  <h4 style={{textAlign:"center", marginTop:"20px"}}>You are not authenticated</h4>
                </div>
              )}
        </div>
    );
};

export default UserAccount;
