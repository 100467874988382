import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAuth } from '../Components/Redux/authSlice';

const AttendanceViewer: React.FC = () => {
  const [classes, setClasses] = useState<any[]>([]);
  const [selectedClass, setSelectedClass] = useState<number | null>(null);
  const [attendanceRecords, setAttendanceRecords] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    date: '',
    status: '',
    student_id: '',
    remarks: '',
  });
  const [students, setStudents] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(''); // Add state for selected date
  const dispatch = useDispatch();

  // Function to get a cookie by name
  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const { data } = await axios.get('https://api.samahait.com/users/user');
        dispatch(setAuth(true));
      } catch (e) {
        dispatch(setAuth(false));
      }
    };

    fetchUserData();
  }, [dispatch]);

  // Fetch classes on component mount
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get('https://api.samahait.com/Branches/classes/');
        setClasses(response.data);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchClasses();
  }, []);

  // Fetch students by class
  useEffect(() => {
    if (selectedClass) {
      const fetchStudents = async () => {
        try {
          const response = await axios.get(`https://api.samahait.com/Branches/students/?class_id=${selectedClass}`);
          setStudents(response.data.results || response.data);
        } catch (error) {
          console.error('Error fetching students:', error);
          setStudents([]);
        }
      };

      fetchStudents();
    }
  }, [selectedClass]);

  // Fetch attendance records based on the selected class and date
  const fetchAttendanceRecords = async (classId: number, date: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://api.samahait.com/Branches/attendance/', {
        params: {
          class_id: classId,
          date: date,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('token')}`, // Add authorization header
        }
      });
      setAttendanceRecords(response.data);
    } catch (error) {
      console.error('Error fetching attendance records:', error);
      setError('Failed to load attendance records');
      setAttendanceRecords([]);
    } finally {
      setLoading(false);
    }
  };

  const handleClassChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const classId = Number(e.target.value);
    setSelectedClass(classId);
    if (selectedDate) {
      fetchAttendanceRecords(classId, selectedDate);
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    setSelectedDate(date);
    if (selectedClass !== null) {
      fetchAttendanceRecords(selectedClass, date);
    }
  };

  // Handle input changes in the form
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    if (type === 'date') {
      setFormData({ ...formData, [name]: value });
      setSelectedDate(value); // Update selectedDate state for date input
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post('https://api.samahait.com/Branches/attendance/', {
        date: formData.date,
        status: formData.status,
        student: parseInt(formData.student_id, 10), // Ensure ID is sent as integer
        class_associated: selectedClass, // Ensure ID is sent as integer
        remarks: formData.remarks, // Include remarks
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('token')}`, // Add authorization header
        }
      });
      alert('Attendance recorded successfully');
      setFormVisible(false); // Hide the form after successful submission
      
      // Refresh attendance records
      if (selectedClass && selectedDate) {
        fetchAttendanceRecords(selectedClass, selectedDate);
      }
    } catch (error) {
      console.error('Error recording attendance:', error);
      alert('Error recording attendance. Please try again.');
    }
  };

  return (
    <div className="container-attendace">
      <h2>Attendance Viewer</h2>

      {/* Button to show form */}
      <button onClick={() => setFormVisible(true)} className="btn btn-primary">
        Add Attendance
      </button>

      {/* Form to add attendance */}
      {formVisible && (
        <div className="form-overlay">
          <div className="form-container">
            <h2>Add Attendance</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="student_id">Student</label>
                <select
                  id="student_id"
                  name="student_id"
                  value={formData.student_id}
                  onChange={handleInputChange}
                  className="form-select"
                  required
                >
                  <option value="">Select Student</option>
                  {students.map((student) => (
                    <option key={student.id} value={student.id}>
                      {student.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className="form-select"
                  required
                >
                  <option value="">Select Status</option>
                  <option value="Present">Present</option>
                  <option value="Absent">Absent</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="remarks">Remarks</label>
                <textarea
                  id="remarks"
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <button type="submit" className="btn btn-primary">Submit</button>
              <button type="button" onClick={() => setFormVisible(false)} className="btn btn-secondary ml-2">Close</button>
            </form>
          </div>
        </div>
      )}

      {/* Class Dropdown */}
      <div className="mb-3">
        <label htmlFor="classSelect" className="form-label">Select Class:</label>
        <select id="classSelect" onChange={handleClassChange} className="form-select">
          <option value="">Select Class</option>
          {classes.map((classItem) => (
            <option key={classItem.id} value={classItem.id}>
              {classItem.name}
            </option>
          ))}
        </select>
      </div>

      {/* Date Input */}
      {selectedClass && (
        <div className="mb-3">
          <label htmlFor="dateInput" className="form-label">Select Date:</label>
          <input
            type="date"
            id="dateInput"
            value={selectedDate}
            onChange={handleDateChange}
            className="form-control"
          />
        </div>
      )}

      {/* Display Attendance Records */}
      {loading && <p>Loading...</p>}
      {error && <p className="text-danger">{error}</p>}
      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Student</th>
            <th>Status</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {attendanceRecords.map((record) => (
            <tr key={record.id}>
              <td>{record.date}</td>
              <td>{record.student_name}</td>
              <td>{record.status}</td>
              <td>{record.remarks}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceViewer;
