import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import BranchForm from './BranchForm';
import { setAuth } from '../Components/Redux/authSlice';
import { useDispatch } from 'react-redux';

interface Branch {
  id: number;
  name: string;
  address: string;
  contact_number: string;
  manager: string;
  logo?: string; // Optional logo field
}

// Function to get cookie value by name
const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
};

const BranchList: React.FC = () => {
  const [branches, setBranches] = useState<Branch[]>([]);
  const [editBranch, setEditBranch] = useState<Branch | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const { data } = await axios.get('https://api.samahait.com/users/user');
        dispatch(setAuth(true));
      } catch (e) {
        dispatch(setAuth(false)); // Corrected dispatch usage
      }
    };

    fetchData();
  }, [dispatch]);

  const fetchBranches = async () => {
    try {
      const response = await axios.get('https://api.samahait.com/Branches/branches/');
      setBranches(response.data);
    } catch (error) {
      setError('Failed to fetch branches.');
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  const handleDelete = async (id: number) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this branch?");
    if (confirmDelete) {
      try {
        await axios.delete(`https://api.samahait.com/Branches/branches/${id}/`);
        setSuccess('Branch deleted successfully');
        fetchBranches();
      } catch (error) {
        setError('Failed to delete branch.');
      }
    }
  };

  const handleEdit = (branch: Branch) => {
    setEditBranch(branch);
  };

  const handleSuccess = () => {
    setEditBranch(null);
    fetchBranches();
    setSuccess('Branch updated successfully');
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar (240px width) */}
        <div className="col-lg-3 bg-light">
          {/* Sidebar content goes here */}
          Sidebar Content
        </div>
        {/* Main Content */}
        <div className="col-lg-9 mt-5">
          <h2>Branch List</h2>
          {success && <div className="alert alert-success mt-3">{success}</div>}
          {error && <div className="alert alert-danger mt-3">{error}</div>}
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Contact Number</th>
                <th>Manager</th>
                <th>Logo</th> {/* Add column for logo */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {branches.map((branch) => (
                <tr key={branch.id}>
                  <td>{branch.name}</td>
                  <td>{branch.address}</td>
                  <td>{branch.contact_number}</td>
                  <td>{branch.manager}</td>
                  <td>
                    {branch.logo ? (
                      <img
                        src={branch.logo} // Use the absolute URL directly
                        alt={`${branch.name} logo`} // Use branch name for alt text
                        style={{ width: '50px', height: 'auto' }} // Adjust size as needed
                      />
                    ) : (
                      'No logo'
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-warning me-2"
                      onClick={() => handleEdit(branch)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(branch.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {editBranch && <BranchForm branch={editBranch} onSuccess={handleSuccess} />}
        </div>
      </div>
    </div>
  );
};

export default BranchList;
