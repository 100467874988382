import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../Components/Redux/store';
import { setAuth } from '../Components/Redux/authSlice';

const IncomeExpenseSummary: React.FC = () => {
    const [incomeData, setIncomeData] = useState<{ [key: string]: { total_income: string; total_expenses: string; total_salaries: string; net_income: string } }>({});
    const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', role: '' });
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth.value);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    const fetchUserData = async () => {
        try {
            const token = getCookie('token');
            if (!token) {
                dispatch(setAuth(false));
                return;
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const { data } = await axios.get('https://api.samahait.com/users/user');
            setUserInfo({ firstName: data.first_name, lastName: data.last_name, role: data.role });
            console.log('User Info:', data);
            dispatch(setAuth(true));
        } catch (e) {
            console.error('Error fetching user data:', e);
            dispatch(setAuth(false));
        }
    };

    const fetchIncomeData = async (year: number) => {
        try {
            const response = await axios.get(`https://api.samahait.com/fees/income/?year=${year}`);
            setIncomeData(response.data.income_and_expenses_by_month || {});
            console.log(`Income Data for ${year}:`, response.data.income_and_expenses_by_month);
        } catch (error) {
            console.error('Error fetching income data:', error);
            setIncomeData({});
        }
    };

    const handleYearChange = (direction: 'previous' | 'next') => {
        setCurrentYear(prevYear => {
            const newYear = direction === 'previous' ? prevYear - 1 : prevYear + 1;
            fetchIncomeData(newYear);
            return newYear;
        });
    };

    useEffect(() => {
        fetchUserData();
    }, [dispatch]);

    useEffect(() => {
        if (auth) {
            fetchIncomeData(currentYear);
        }
    }, [auth, currentYear]);

    return (
        <>
            <div className="container mt-4">
                {auth ? (
                    <div>
                        <h3 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Income and Expense Summary</h3>
                        
                        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <button 
                                className="btn btn-primary me-2"
                                onClick={() => handleYearChange('previous')}
                            >
                                Previous Year
                            </button>
                            <span style={{ fontSize: '1.5rem', margin: '0 15px' }}>{currentYear}</span>
                            <button 
                                className="btn btn-primary ms-2"
                                onClick={() => handleYearChange('next')}
                            >
                                Next Year
                            </button>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-bordered table-hover income-expense-table" style={{ width: '730px', margin: '10px auto' }}>
                                <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th>Total Income</th>
                                        <th>Total Expenses</th>
                                        <th>Total Salaries</th>
                                        <th>Net Income</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(incomeData).length > 0 ? (
                                        Object.keys(incomeData).map((month, index) => (
                                            <tr key={month} className={index % 2 === 0 ? 'even-row' : ''}>
                                                <td>{month}</td>
                                                <td>ksh {incomeData[month].total_income}</td>
                                                <td>ksh {incomeData[month].total_expenses}</td>
                                                <td>ksh {incomeData[month].total_salaries}</td>
                                                <td>ksh {incomeData[month].net_income}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5} className="text-center">No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h1>You are not authenticated or authorized to view this page</h1>
                    </div>
                )}
            </div>
        </>
    );
};

export default IncomeExpenseSummary;
