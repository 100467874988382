import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { setAuth } from './Redux/authSlice';
import { RootState } from './Redux/store';
import Charts from './Charts';
import "../App.css"

const Dashboard = () => {
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState<{ firstName: string; lastName: string, branchLogo:string, branchName: string; }>({ firstName: '', lastName: '', branchLogo:'',  branchName: '', });
    const [userRole, setUserRole] = useState('');
    const auth = useSelector((state: RootState) => state.auth.value);
    const [numStudents, setNumStudents] = useState(0);
    const [numTeachers, setNumTeachers] = useState(0);
    const [feesCount, setFeesCount] = useState(0);
    const [feesNotpaid, setNotpaid] = useState(0);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [incomeData, setIncomeData] = useState<{ [key: string]: number }>({});
    const [totalIncome, setTotalIncome] = useState<string>('');
    const [totalExpenses, setTotalExpenses] = useState<string>('');
    const[totalSalary, setTotalSalary] = useState<string>('')
    const [netIncome, setNetIncome] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Set Authorization header
                const response = await axios.get('https://api.samahait.com/fees/income/');
                setIncomeData(response.data.income_and_expenses_by_month);

                // Ensure the current month data exists before setting state
                const currentMonth = currentDateTime.toLocaleString('default', { month: 'long' });
                if (response.data.income_and_expenses_by_month && response.data.income_and_expenses_by_month[currentMonth]) {
                    const currentMonthData = response.data.income_and_expenses_by_month[currentMonth];
                    setTotalIncome(currentMonthData.total_income);
                    setTotalExpenses(currentMonthData.total_expenses);
                    setNetIncome(currentMonthData.net_income);
                    setTotalSalary(currentMonthData.total_salaries);
                } else {
                    console.log(`No data found for ${currentMonth}`);
                }
            } catch (error) {
                console.error('Error fetching income data:', error);
            }
        };

        fetchData();
    }, [currentDateTime]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const response = await axios.get('https://api.samahait.com/users/user');
                
                // Ensure you are accessing the correct fields based on your API response
                const { first_name, last_name, branch_logo, branch_name} = response.data.user; 

                // Update the state with the fetched data
                setUserInfo({ firstName: first_name, lastName: last_name, branchLogo:branch_logo, branchName:branch_name});
                dispatch(setAuth(true));
                console.log("user:", branch_logo);
                
            } catch (e) {
                console.error('Error fetching user data:', e);
                dispatch(setAuth(false));
                setError('Failed to fetch user data');
            }
        };

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        const fetchFeesCount = async () => {
            try {
                const response = await axios.get('https://api.samahait.com/fees/fees-count/');
                setFeesCount(response.data.num_fees_paid);
            } catch (error) {
                console.error('Error fetching fees count:', error);
            }
        };
        const fetchFeesNotPaidCount = async () => {
            try {
                const response = await axios.get('https://api.samahait.com/fees/fees_not_paid_count/');
                setNotpaid(response.data.num_students_not_paid);
            } catch (error) {
                console.error('Error fetching fees count:', error);
            }
        };

        const fetchStudentCount = async () => {
            try {
                const response = await axios.get('https://api.samahait.com/Branches/students-count/');
                setNumStudents(response.data.num_students);
            } catch (error) {
                console.error('Error fetching student count:', error);
            }
        };

        const fetchTeacherCount = async () => {
            try {
                const response = await axios.get('https://api.samahait.com/Branches/teacher_count/');
                setNumTeachers(response.data.num_teachers);
            } catch (error) {
                console.error('Error fetching teacher count:', error);
            }
        };
        fetchFeesCount();
        fetchFeesNotPaidCount();
        fetchStudentCount();
        fetchTeacherCount();
    }, []);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    // const cardStyle = {
    //     backgroundColor: branchName === 'Mustaqbal university' ? '#3399FF' : branchName === '#3399FF' ? 'orange' : '#3399FF',
    //     color: '#ffffff',
    //     border: '1px solid #fff',
    //     height:"90px",
    //     marginTop:"20px",
    //     boxShadow: '0px 0px 10px rgba(0, 0, 10, 0.2)', // Adding box shadow
    // };
    const ExpenseCrad = {
        backgroundColor: "#DC3545",
        color: '#ffffff',
        border: '1px solid #fff',
        height:"90px",
        marginTop:"10px",
        boxShadow: '0px 0px 10px rgba(0, 0, 10, 0.2)', // Adding box shadow
    };
    
    const studentsCard = {
        backgroundColor: "#3399FF",
        color: '#ffffff',
        border: '1px solid #fff',
        height:"90px",
        marginTop:"10px",
        boxShadow: '0px 0px 10px rgba(0, 0, 10, 0.2)', // Adding box shadow
    };
    const teachersCard = {
        backgroundColor: "#6C757D",
        color: '#ffffff',
        border: '1px solid #fff',
        height:"90px",
        marginTop:"10px",
        boxShadow: '0px 0px 10px rgba(0, 0, 10, 0.2)', // Adding box shadow
    };
    const feesCard = {
        backgroundColor: "#28A745",
        color: '#ffffff',
        border: '1px solid #fff',
        height:"90px",
        marginTop:"10px",
        boxShadow: '0px 0px 10px rgba(0, 0, 10, 0.2)', // Adding box shadow
    };
    const feesNotpaidCard = {
        backgroundColor: "#DC3545",
        color: '#ffffff',
        border: '1px solid #fff',
        height:"90px",
        marginTop:"10px",
        boxShadow: '0px 0px 10px rgba(0, 0, 10, 0.2)', // Adding box shadow
    };
    const income = {
        backgroundColor: "#28A745",
        color: '#ffffff',
        border: '1px solid #fff',
        height:"90px",
        marginTop:"10px",
        boxShadow: '0px 0px 10px rgba(0, 0, 10, 0.2)', // Adding box shadow
    };
    const NetIncome = {
        backgroundColor: "#17A2B8 ",
        color: '#ffffff',
        border: '1px solid #fff',
        height:"90px",
        marginTop:"10px",
        boxShadow: '0px 0px 10px rgba(0, 0, 10, 0.2)', // Adding box shadow
    };
    const salaryies = {
        backgroundColor: "#007BFF",
        color: '#ffffff',
        border: '1px solid #fff',
        height:"90px",
        marginTop:"10px",
        boxShadow: '0px 0px 10px rgba(0, 0, 10, 0.2)', // Adding box shadow
    };
    const buttonStyle = {
        color: '#fff',
    };

    return (
        <div className="container-fluid p-0">
            {loading ? (
                <p>Loading...</p>
            ) : auth ? (
                <div>
                <div className="dashboard-container">
                    <div className="upper-Hero" style={{ backgroundColor: 'grey', color: '#fff' }}>
                        <div>
                            {userInfo ? (
                              <img
                              src={userInfo.branchLogo}
                              className="logoIcon"
                              style={{ width: '50px', marginRight: '10px' }}
                              />
                            ) : (
                                <h1 style={{display:"flex", justifyContent:"center", alignItems:"center"}}>loading .....</h1>
                            )}
                        </div>
                            {/* <h3 style={{display:"flex", justifyContent:"center", alignItems:"center"}}>{userInfo.branchName}</h3> */}
                                <div className="left-hero">
                                    <h4>Dugsi manager</h4>
                                </div>
                              <div>
                              <h6 className='dashH2'>
                              <i className="fa-solid fa-user me-2" style={{ color: 'white' }}></i>
                                Welcome, {userInfo.firstName} {userInfo.lastName}
                            </h6>
                              </div>
                            </div>
                        <div className="middle-hero">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card text-center students-card" style={studentsCard}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-user-graduate" style={{ fontSize: '20px', color: '#fff' }}></i>
                                           <Link to="/students" className="navLink" style={{ color: "white", textDecoration: "none", }}><p className="card-text">Number of students <h5>{numStudents}</h5></p></Link>
                                        </div> 
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-center" style={teachersCard}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-chalkboard-teacher" style={{ fontSize: '20px', color: '#fff' }}></i>
                                          <Link to="/teachers" className="navLink" style={{ color: "white", textDecoration: "none" }}><p className="card-text">Number of teachers <h5>{numTeachers}</h5></p></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-center fees-card" style={feesCard}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-money-bill" style={{ fontSize: '20px', color: '#fff' }}></i>
                                            <Link to="/fees" className="navLink" style={{ color: "white", textDecoration: "none" }}><p className="card-text">Number of students paid <h5>{feesCount}</h5></p></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-center" style={feesNotpaidCard}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-money-bill" style={{ fontSize: '20px', color: '#fff' }}></i>
                                            <Link to="/fees_not_paid" className="navLink" style={{ color: "white", textDecoration: "none" }}> <p className="card-text">Fees Not paid <h5>{feesNotpaid}</h5></p></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-center" style={income}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-money-bill" style={{ fontSize: '20px', color: '#fff' }}></i>
                                            <Link to="/income" className="navLink" style={{ color: "white", textDecoration: "none", }}><p className="card-text">Total income <h5>ksh {totalIncome}</h5></p>  </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-center" style={ExpenseCrad}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-money-bill" style={{ fontSize: '20px', color: '#fff' }}></i>
                                           <Link to="/ExpenseList" className="navLink" style={{ color: "white", textDecoration: "none", }}><p className="card-text">Total expenses <h5>ksh {totalExpenses}</h5></p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-center" style={salaryies}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-wallet" style={{ fontSize: '20px', color: '#fff' }}></i>
                                           <Link to="/income" className="navLink" style={{ color: "white", textDecoration: "none", }}><p className="card-text">Total Salaries <h5>ksh {totalSalary}</h5></p></Link>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card text-center" style={NetIncome}>
                                        <div className="card-body">
                                            <i className="fa-solid fa-money-bill" style={{ fontSize: '20px', color: '#fff' }}></i>
                                           <Link to="/income" className="navLink" style={{ color: "white", textDecoration: "none", }}> <p className="card-text">Net income <h5>ksh {netIncome}</h5></p></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Charts/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p>{error}</p>
            )}
        </div>
    );
};

export default Dashboard;
