import { Link } from "react-router-dom";
import { setAuth } from "./Redux/authSlice";
import axios from "axios";
import { RootState } from "./Redux/store";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import "../App.css";

export const Nav = () => {
    const auth = useSelector((state: RootState) => state.auth.value);
    const [togleside, setToggle] = useState(false);
    const dispatch = useDispatch();
    const [userRole, setUserRole] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);



    const logout = async () => {
        await axios.post('logout', {}, { withCredentials: true });
        deleteTokenCookie();
        dispatch(setAuth(false));
    };

    useEffect(() => {
        const fetchUserRole = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get('https://api.samahait.com/users/user');
                setUserRole(data.user.role); // Ensure you access the role correctly from the response
                console.log('User role:', data.user.role); // Verify role in console
                setAuthenticated(true); // Set authenticated to true after successful login
            } catch (error) {
                console.error('Failed to fetch user role:', error);
            } finally {
                setLoading(false);
            }
        };
    }, []);

    const deleteTokenCookie = () => {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    };

    const handleDocumentClick = (e: MouseEvent) => {
        if (togleside && !(e.target as HTMLElement).closest('.side-wrapper') && !(e.target as HTMLElement).closest('.fa-bars')) {
            setToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [togleside]);

    let links;

    if (auth) {
        links = <div className="text-end">
            <Link to="/" onClick={logout} className="btn btn-danger me-2">Logout</Link>
        </div>

    } else {
        links = <div className="text-end">
            <Link to="/" className="btn btn-outline-light me-2">Login</Link>
        </div>
    }

    return (
        <header className="p-2 text-white sticky-top" style={{backgroundColor:"#3379FF"}}>
            <div className="container-fluid"> {/* Changed to container-fluid */}
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div>
                        {togleside ?
                            <i className="fa-solid fa-xmark" onClick={() => setToggle(false)} style={{cursor:"pointer"}}></i>
                            :
                            <i className="fa-solid fa-bars" onClick={() => setToggle(true)} style={{cursor:"pointer"}}></i>
                        }
                    </div>
                    {links}
                </div>
            </div>
            {togleside && (
                <div className="side-wrapper">
                    <Sidebar />
                </div>
            )}
        </header>
    );
};
