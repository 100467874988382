import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { setAuth } from '../Redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Redux/store';

// Define the getCookie function
const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
  return null;
};

interface Student {
  name: string;
  date_reg: string;
  guardian_number: string | null;
  address: string | null;
  health_status: string | null;
  file_upload: File | null;
  class_associated: number | null;
}

interface Class {
  id: number;
  name: string;
}

const AddStudent: React.FC = () => {
  const [student, setStudent] = useState<Student>({
    name: '',
    date_reg: '',
    guardian_number: null,
    address: null,
    health_status: null,
    file_upload: null,
    class_associated: null,
  });
  const [classes, setClasses] = useState<Class[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const auth = useSelector((state: RootState) => state.auth.value);
  const [error, setError] = useState<string | null>(null);
  const [added, setAdded] = useState<boolean>(false);
  const navigate = useNavigate(); 
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const { data } = await axios.get('https://api.samahait.com/users/user');
        dispatch(setAuth(true));
      } catch (e) {
        console.error('Error fetching user data:', e);
        dispatch(setAuth(false));
        setError('Failed to fetch user data.');
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get<Class[]>('https://api.samahait.com/Branches/classes/');
        setClasses(response.data);
      } catch (error) {
        console.error('Error fetching classes:', error);
        setError('Failed to fetch classes.');
      }
    };

    fetchClasses();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setStudent({
      ...student,
      [name]: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setStudent({
      ...student,
      file_upload: file,
    });
  };

  const handleClassChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStudent({
      ...student,
      class_associated: Number(e.target.value),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      Object.keys(student).forEach(key => {
        const value = student[key as keyof Student];
        if (value !== null) {
          formData.append(key, value instanceof File ? value : String(value));
        }
      });

      await axios.post('https://api.samahait.com/Branches/students/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      setAdded(true);
      alert('Student added successfully.');

      navigate('/students'); 
    } catch (err) {
      setLoading(false);
      console.error('Error adding student:', err);
      if (axios.isAxiosError(err)) {
        console.error('Error response:', err.response?.data);
        setError(err.response?.data?.detail || 'Failed to add student.');
      } else {
        setError('Failed to add student.');
      }
    }
  };

  if (added) {
    return <Navigate to="/students" />;
  }

  return (
    <div className="container mt-5">
       {auth ? (
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <h2 className="mb-4 text-center">Add Student</h2>
          <form onSubmit={handleSubmit} className="add-student-form">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="name" className="form-label">Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={student.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="date_reg" className="form-label">Date of Registration:</label>
                <input
                  type="date"
                  className="form-control"
                  id="date_reg"
                  name="date_reg"
                  value={student.date_reg}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="guardian_number" className="form-label">Guardian Number:</label>
                <input
                  type="text"
                  className="form-control"
                  id="guardian_number"
                  name="guardian_number"
                  value={student.guardian_number || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="address" className="form-label">Address:</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  value={student.address || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="health_status" className="form-label">Health Status:</label>
                <input
                  type="text"
                  className="form-control"
                  id="health_status"
                  name="health_status"
                  value={student.health_status || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="file_upload" className="form-label">File Upload:</label>
                <input
                  type="file"
                  className="form-control"
                  id="file_upload"
                  name="file_upload"
                  onChange={handleFileChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="class_associated" className="form-label">Class:</label>
                <select
                  className="form-select"
                  id="class_associated"
                  name="class_associated"
                  value={student.class_associated !== null ? student.class_associated : ''}
                  onChange={handleClassChange}
                  required
                >
                  <option value="">Select Class</option>
                  {classes.map(cls => (
                    <option key={cls.id} value={cls.id}>
                      {cls.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {error && <p className="text-danger">Error: {error}</p>}
            <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
              {loading ? 'Adding...' : 'Add Student'}
            </button>
          </form>
        </div>
      </div>
      ) : (
        <div>
          <h4 style={{textAlign:"center", marginTop:"20px"}}>You are not authenticated</h4>
        </div>
      )}
    </div>
  );
};

export default AddStudent;

