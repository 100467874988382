import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAuth } from '../Components/Redux/authSlice';

const UserComponent: React.FC = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [userRole, setUserRole] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const [authenticated, setAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    console.error('No token found in cookies');
                    dispatch(setAuth(false));
                    setError('No token found. Please log in.');
                    return;
                }
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const usersResponse = await axios.get('https://api.samahait.com/users/all-users/');
                setUsers(usersResponse.data);
                fetchUserRole(); // Call fetchUserRole after fetching users
            } catch (err) {
                console.error('Error fetching users:', err);
                handleAxiosError(err);
            }
        };

        const fetchUserRole = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get('https://api.samahait.com/users/user');
                setUserRole(data.user.role);
                setAuthenticated(true);
                console.log('Logged in user role:', data.user.role); // Log user role
            } catch (error) {
                console.error('Failed to fetch user role:', error);
                setError('Failed to fetch user role');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [dispatch]);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    const handleDelete = async (userId: number) => {
        if (!window.confirm('Are you sure you want to delete this user?')) {
            return;
        }

        try {
            const token = getCookie('token');
            if (!token) {
                throw new Error('No token found in cookies');
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            await axios.delete(`https://api.samahait.com/users/users/${userId}/`);
            setUsers(users.filter(user => user.id !== userId));
        } catch (err) {
            console.error('Error deleting user:', err);
            handleAxiosError(err);
        }
    };

    const handleAxiosError = (err: any) => {
        if (axios.isAxiosError(err)) {
            if (err.response) {
                setError(`Error: ${err.response.status} ${err.response.statusText}`);
            } else if (err.request) {
                setError('Error: No response received from the server');
            } else {
                setError(`Error: ${err.message}`);
            }
        } else {
            setError('An unexpected error occurred');
        }
    };

    // Render nothing if userRole is not admin or super_admin
    if (userRole !== 'admin' && userRole !== 'super_admin') {
        return <div className="container" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"200px"}}>
            <div className="row">
                <div className="col-md-12">
                    <div className="alert alert-danger">
                        You don't have the right to view this page.
                    </div>
                </div>
            </div>
        </div>;
    }

    // Render user table if userRole is admin or super_admin
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="user-table">
                        <h4 style={{display:"flex", alignItems:"center", justifyContent:"center"}}>User Table</h4>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Branch Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => (
                                        <tr key={user.id}>
                                            <td>{user.first_name}</td>
                                            <td>{user.last_name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.role}</td>
                                            <td>{user.branch_name}</td>
                                            <td>
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleDelete(user.id)}
                                                    >
                                                        Delete
                                                    </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserComponent;
