import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Components/Redux/store';
import { setAuth } from '../Components/Redux/authSlice';

interface Exam {
  id: number;
  exam_name: string;
  date: string;
}

export default function ShowExams() {
  const [exams, setExams] = useState<Exam[]>([]);
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [editableExam, setEditableExam] = useState<Exam | null>(null);
  const [userRole, setUserRole] = useState<string>('');
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth.value);
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });

  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  const fetchUserData = async () => {
    try {
      const token = getCookie('token');
      if (!token) {
        dispatch(setAuth(false));
        setError('No authentication token found. Please log in.');
        setLoading(false);
        return;
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const { data } = await axios.get('https://api.samahait.com/users/user', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setUserInfo({ firstName: data.first_name, lastName: data.last_name });
      setUserRole(data.role); // Assuming the role is fetched from the backend
      setAuthenticated(true);
      dispatch(setAuth(true));
    } catch (e) {
      dispatch(setAuth(false));
      setError('Error fetching user data.');
      setLoading(false);
    }
  };

  const fetchExamData = async () => {
    try {
      const token = getCookie('token');
      if (!token) {
        setError('No authentication token found. Please log in.');
        setLoading(false);
        return;
      }
      const response = await axios.get('https://api.samahait.com/exams/exams/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setExams(response.data);
    } catch (err: any) {
      console.error('Error fetching Exam data:', err);
      setError(err.response?.data?.detail || 'Error fetching Exam data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchExamData();
  }, []);

  const handleEditClick = async (exam: Exam) => {
    if (['admin', 'super_admin'].includes(userRole)) {
      alert('You do not have permissions to edit exams.');
      return;
    }
    if (isEditing !== null && editableExam) {
      try {
        const token = getCookie('token');
        if (!token) {
          setError('No authentication token found. Please log in.');
          return;
        }
        const response = await axios.patch(`https://api.samahait.com/exams/exams/${editableExam.id}/`, editableExam, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Server response:', response.data);
        setExams(exams.map(e => (e.id === editableExam.id ? editableExam : e)));
      } catch (error: any) {
        console.error('Error updating exam:', error.response ? error.data : error.message);
      }
      setIsEditing(null);
      setEditableExam(null);
    } else {
      setIsEditing(exam.id);
      setEditableExam(exam);
    }
  };

  const handleDeleteClick = async (examId: number) => {
    if (!['admin', 'super_admin'].includes(userRole)) {
      alert('You do not have permissions to delete exams.');
      return;
    }

    try {
      const token = getCookie('token');
      if (!token) {
        setError('No authentication token found. Please log in.');
        return;
      }
      // Confirmation before deletion
      if (window.confirm('Are you sure you want to delete this exam?')) {
        await axios.delete(`https://api.samahait.com/exams/exams/${examId}/`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        setExams(exams.filter(exam => exam.id !== examId));
      }
    } catch (error: any) {
      console.error('Error deleting exam:', error.response ? error.response.data : error.message);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (editableExam) {
      setEditableExam({ ...editableExam, [name]: value });
    }
  };

  if (!authenticated) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <div className="container mt-3">
      <h2 style={{textAlign:"center"}}>Exams Details</h2>
      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered table-sm">
          <thead className='thead-dark'>
            <tr>
              <th>Exam Name</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {exams && exams.length > 0 ? (
              exams.map(exam => (
                <tr key={exam.id}>
                  <td>
                    {isEditing === exam.id ? (
                      <input
                        type="text"
                        name="exam_name"
                        value={editableExam?.exam_name || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      exam.exam_name
                    )}
                  </td>
                  <td>
                    {isEditing === exam.id ? (
                      <input
                        type="text"
                        name="date"
                        value={editableExam?.date || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      exam.date
                    )}
                  </td>
                  <td>
                    {(['admin', 'super_admin'].includes(userRole) && isEditing === exam.id) ? (
                      <button className="btn btn-primary" onClick={() => handleEditClick(exam)}>
                        Save
                      </button>
                    ) : (
                      <button className="btn btn-primary" onClick={() => handleEditClick(exam)}>
                        Edit
                      </button>
                    )}
                    {(['admin', 'super_admin'].includes(userRole)) && (
                      <button className="btn btn-danger ms-2" onClick={() => handleDeleteClick(exam.id)}>
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No exams found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
